.groups {
    border-radius: .5rem;
    /*border: 1px solid #005b7c;*/
    margin-top: 1rem;
    padding: 1rem;
}

.group_name {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-right: .5rem;
}

.wrap_groups {
    display: flex;
}

.wrap_groups button {
    padding: .2rem;
    height: 2rem;
}

.hint {
    opacity: .5;
    padding-left: 1rem;
}

.header {
    background-color: #a7dadd;
    color: #005b7c;
    border-radius: .5rem;
    padding: 1rem;
    justify-content: space-between;
    display: flex;
    align-items: center;
    border: 1px solid #005b7c;
    font-size: var(--header-font-size);
}

.btn {
    color: #a7dadd;
    font-size: 1rem;
    display: inline-block;
    border: 1px solid #005b7c;
    border-radius: .5rem;
    background-color: #005b7c;
    text-decoration: none;
    padding: 1rem .5rem 1rem .5rem;
    cursor: pointer;
    /*transition: .5s;*/
}

.btn:hover {
    /*transform: scale(1.1)*/
}

