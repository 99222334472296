body {
  margin: 0;
  padding: 1rem;
  width: calc(100vw - 2rem);
  height: calc(100vh - 2rem);
  overflow: hidden;
}

#root, .App {
  width: 100%;
  height: 100%;
}

.login {
  display: grid;
  justify-content: center;
  min-width: min-content;
}

.login h3 {
  font-size: xx-large;
  grid-column: 1 / 3;
}

.login form {
  display: grid;
  grid-template-columns: 5rem auto;
}

.login form a {
  width: max-content;
}

.login input {
  border: 1px solid #359ed0;
  border-radius: .3rem;
  padding: .3rem;
  grid-column: 2;
}

.login input:focus {
  outline: none;
  border-color: black;
}

.login * {
  margin: .5rem;
  align-self: center;
  grid-column: 1 / 1;
}

.btn {
  background: cadetblue;
  border-radius: .7rem;
  border-width: 0;
  cursor: pointer;
  color: white;
  font-size: 1rem;
  font-weight: bold;
  padding: .3rem;
  grid-column: 1 / 3;
}

.error {
  font-size: .8rem;
  grid-column: 2;
  margin: 0 0 .5rem .5rem;
  color: red;
}
