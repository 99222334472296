.project_list {
    display: grid;
    grid-template-columns: [sidebar] 1fr [btn] 2fr;
    grid-gap: 1rem;
    height: 100%;
    grid-template-rows: [head] auto [content] 1fr;
}

.project_list p {
    font-size: var(--header-font-size);
    font-weight: bold;
    margin: 0;
}

.btn {
    color: #a7dadd;
    font-size: 1rem;
    display: inline-block;
    border: 1px solid #005b7c;
    border-radius: .5rem;
    background-color: #005b7c;
    text-decoration: none;
    cursor: pointer;
    /*transition: .5s;*/
    height: 2rem;
}

.btn:hover {
    /*transform: scale(1.1)*/
}

.project_list ul {
    margin: 0;
    padding: 1rem;
}

.project_list ul li {
    padding: .3rem;
    font-size: 1rem;
    display: flex;
    justify-content: space-between;
}

.project_list hr {
    grid-row: content;
    grid-column: sidebar;
    height: 0;
    width: 100%;
}

.project_list a {
    grid-column: btn;
    grid-row: head;
    align-self: center;
    text-decoration: none;
}

.buttons {
    display: flex;
    justify-content: space-between;
    grid-column: btn;
    grid-row: head;
    background-color: #a7dadd;
    color: #005b7c;
    border-radius: .5rem;
    padding: 1rem;
    border: 1px solid #005b7c;
    font-size: 1rem;
    height: 2rem;
    align-items: center;
}

.members {
    width: 100%;
    grid-column: btn;
    grid-row: content;
}

.members_title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: var(--header-font-size);
    font-weight: bold;
    padding-left: 1rem;
}

.user_list {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-left: 1rem;
}

.projects {
    grid-column: 1;
    grid-row: 1 / 3;
    background-color: #a7dadd;
    color: #005b7c;
    border-radius: .5rem;
    padding: 1rem;
    border: 1px solid #005b7c;
    font-size: var(--header-font-size);
    overflow-y: auto;
}
