.scale {
    width: 100%;
    height: 3rem;
    border: black solid thin;
    display: flex;
}

.fragment {
    height: 100%;
    border-left: black dashed thin;
    border-right: black dashed thin;
    cursor: pointer;
}

.fragment:hover {
    background: repeating-linear-gradient(-45deg, white, white 5px, lightcyan 5px, lightcyan 10px);
}

.cursor {
    position: absolute;
    width: 0.2rem;
    height: 3.1rem;
    background-color: mediumvioletred;
    cursor: move;
}
