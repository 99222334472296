.editor {
    border: lightgray thin solid;
    border-radius: 0.3rem;
    width: 100%;
    height: 12rem;
}

.editor > div {
    height: 100%;
}

.editor div:global(.react-contextmenu-wrapper) {
    height: 100%;
    display: grid;
    grid-template-rows: 2.5rem 1fr;
}

.content {
    height: calc(100% - 1rem);
    padding: 0.5rem;
    overflow-y: auto;
}

.content .label {
    background-color: rgba(149, 228, 169, .4);
}

.content .label:hover {
    background-color: rgba(149, 228, 169, .6);
}

.menu {
    transition: color 0.15s, background-color 0.15s;
    position: absolute;
    min-width: 160px;
    padding: 4px 0px;
    list-style: none;
    background-clip: padding-box;
    border-radius: 4px;
    box-shadow: 0 1px 15px rgba(27, 31, 35, 0.15);
    margin: 0;
    background-color: white;
    color: #333;
    border: 1px solid rgba(27, 31, 35, 0.15);
}

.menuitem {
    list-style: none;
    display: block;
    padding: 4px 8px 4px 16px;
    overflow: hidden;
    /*color: ${p => (p.$isOpen || p.$highlight ? "white" : "inherit")};*/
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 14px;
    line-height: 1.5;
    cursor: pointer;
    position: relative;
    user-select: none;
}

.menuitem:hover {
    background-color: #359ed0;
    color: white;
}

.parent:after {
    content: "►";
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 10px;
}

.toolbar {
    height: 1.5rem;
    padding: 0.5rem;
    border-bottom: 2px solid rgb(238, 238, 238);
    display: flex;
    gap: 1rem;
}

.toolbar .button {
    padding: 0.2rem;
    background-color: rgba(255, 105, 119, 0.5);
    border-radius: 0.3rem;
    display: inline-flex;
}

.toolbar .icon {
    cursor: pointer;
}

.wrapper {
    display: inline-flex;
    flex-direction: column;
    vertical-align: bottom;
}
