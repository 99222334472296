.player {
}

.controls {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.controls svg {
    cursor: pointer;
}

.duration {
    width: 8rem;
}

.title {
    width: 100%;
    text-align: center;
}

.progress {
    display: flex;
    justify-content: end;
    gap: 1rem;
}
