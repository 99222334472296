.wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.form {
    display: grid;
    grid-template-columns: 1fr 3fr;
    grid-template-rows: repeat(5, 3rem);
}

.header {
    background-color: #a7dadd;
    color: #005b7c;
    border-radius: .5rem;
    padding: 1rem;
    justify-content: space-between;
    display: flex;
    align-items: center;
    border: 1px solid #005b7c;
    font-size: var(--header-font-size);
}

.btn {
    color: #a7dadd;
    font-size: 1rem;
    display: inline-block;
    border: 1px solid #005b7c;
    border-radius: .5rem;
    background-color: #005b7c;
    text-decoration: none;
    padding: 1rem .5rem 1rem .5rem;
    cursor: pointer;
    /*transition: .5s;*/
}

.btn:hover {
    /*transform: scale(1.1)*/
}

.results {
    display: grid;
    grid-template-columns: 2rem 2fr 2fr 5fr 1fr;
    overflow-y: auto;
}

.content > p:first-child {
    margin-top: 0;
}

.sub_header {
    font-weight: bold;
}
