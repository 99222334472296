.text_table {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-rows: 3rem 1fr;
}

.text_table {

}

.head {
    border: black solid thin;
}

.head > div {
    text-align: center;
    line-height: 3rem;
}

.head,
.body {
    width: 100%;
}

.body {
    overflow-y: auto;
}

.head,
.row {
    display: grid;
    grid-template-columns: 5rem 10rem 1fr 15rem;
    column-gap: 0.5rem;
}

.row {
    margin-top: 1rem;
}

.row:nth-child(even) {
    background-color: #f3f3f3;
}

.time {
    display: flex;
    gap: 0.3rem;
    padding-left: 0.2rem;
    padding-right: 0.2rem;
}

.time > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.select {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
