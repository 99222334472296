.transcriber {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-rows: minmax(6rem, auto) 3rem minmax(0, 1fr);
}

.controls {
}

.table {
}

.button {
    cursor: pointer;
}

.unsaved {
    color: red;
}

.features {
    display: flex;
    gap: 1rem;
    width: 100%;
}

.features .header {
    text-align: center;
    line-height: 3rem;
}

.features .select {
    flex-grow: 1;
}
